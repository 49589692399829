

  export  const Automation = [
    {
      title: "Boxing Edit",
      videoUrl: "https://vimeo.com/924683141?share=copy",
    },
    {
      title: "Crime-Edit1",
      videoUrl: "https://vimeo.com/924683325?share=copy",
  
    },
    
    {
      title: "NBA-Edit",
      videoUrl: "https://vimeo.com/924684024?share=copy",
  
    },
    {
      title: "Documentary-Edit",
      videoUrl: "https://vimeo.com/924683703?share=copy",
  
    },
    {
      title: "Cricket-Edit",
      videoUrl: "https://vimeo.com/924683228?share=copy",
  
    },
    {
      title: "NFL-Edit",
      videoUrl: "https://vimeo.com/924684138?share=copy",
  
    },
    {
      title: "Movies Recap-Edit1",
      videoUrl: "https://vimeo.com/924683827?share=copy",
  
    },
    {
      title:"Celebrity Niche",
      videoUrl:"https://vimeo.com/924683585?share=copy",
    },
    {
      title:"Crime Niche",
      videoUrl:" https://vimeo.com/924683383?share=copy",
    }
    
   
  
    // Add more projects with video URLs and poster image URLs
  ];
  
  export default Automation;